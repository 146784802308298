import forEach from 'lodash/forEach'
import { cloneDeep, forOwn } from 'lodash'
import { getShopFilters, setShopFilters } from '../store/shopFiltersSlice'
import { useMemo } from 'react'

import useSnapshot from '../store/useSnapshot'

export function prepareFilterParams (filters) {
  const queryParams = new URLSearchParams()

  for (const [key, value] of Object.entries(filters)) {
    if (Array.isArray(value)) {
      const joinedArr = value.join(';')
      queryParams.append(key, joinedArr)
    } else {
      queryParams.append(key, value)
    }
  }

  return `${queryParams.toString()}`
}

export function filterIds (obj) {
  if (Array.isArray(obj)) {
    return obj.map(item => {
      return item.name ? item.name : item.value
    })
  } else if (typeof obj === 'object' && obj !== null) {
    const newObj = {}
    for (const key in obj) {
      if (key === 'inStockOnly') {
        newObj[key] = filterIds(obj[key])
      }
      if (Array.isArray(obj[key]) && obj[key].length > 0 && key !== '_id') {
        newObj[key] = filterIds(obj[key])
      }
    }
    return newObj
  } else {
    return obj
  }
}

export function filterUrlParams (obj) {
  if (typeof window !== 'undefined') {
    const filteredIds = filterIds(obj)
    const filteredParams = prepareFilterParams(filteredIds)
    // using router here causes page reloads on every filter selection, so using window
    window.history.replaceState('', 'unused', `${window.location.pathname}?${filteredParams}`)
  }
}

export function setFilters (filters, queryParams) {
  const snap = useSnapshot()
  const activeFilters = cloneDeep(getShopFilters(snap))
  let updatePossible = false
  useMemo(() => {
    if (snap.shopFilters.fromUrlOnce === false) {
      forOwn(queryParams, (param, key) => {
        if (param && typeof param === 'string' && param !== 'slug') {
          if (key in filters) {
            const filterParam = param.split(';')
            forEach(filterParam, value => {
              if (key === 'inStockOnly') {
                activeFilters[key] = (filterParam[0] === 'true')
              } else {
                const testExists = filters[key].find(obj => obj.name === value || obj.value === value)
                if (testExists) {
                  updatePossible = true
                  activeFilters[key].push(testExists)
                }
              }
              // console.log(`values ${value},`, testExists)
            })
          }
        }
      })
      if (updatePossible === true && snap.shopFilters.fromUrlOnce === false) {
        setShopFilters(activeFilters, false)
      }
    }
  }, [filters, queryParams, activeFilters])
}
