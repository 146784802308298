import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const SENTRY_ENV = process.env.SENTRY_ENV || process.env.NEXT_PUBLIC_SENTRY_ENV

Sentry.init({
  dsn: SENTRY_DSN || 'https://61dfb6117edf4102996ea42f9343e76c@o310117.ingest.sentry.io/6367180',
  tracesSampleRate: 0,
  environment: SENTRY_ENV || 'development'
})
